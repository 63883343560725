import { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import Can from 'src/components/Can';
import Grid from 'src/components/Grid';
import { Cancel, Next, Prev, Save } from 'src/components/Icons';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Stepper from 'src/components/Stepper';
import Text from 'src/components/Text';
import Title from 'src/components/Title';
import useStepFormControl from 'src/hooks/useStepFormControl';
import api from 'src/services/api';
import { basicDataSchema } from 'src/validators/providers/store/index';

import AlertModal from './AlertModal';
import BasicData from './BasicData';
import Certificates from './Certificates';
import Employees from './Employees';
import Licenses from './Licenses';
import { Container, Content, Controls, Head, HeadContent } from './styles';

const Store = (): JSX.Element => {
  const history = useHistory();

  const nextButtonRef = useRef<IButtonHandlers>(null);

  // Refs of forms
  const formRefBasicData = useRef<FormHandles>(null);
  const formRefCertificates = useRef<FormHandles>(null);
  const formRefEmployees = useRef<FormHandles>(null);
  const formRefLicenses = useRef<FormHandles>(null);
  const modalRef = useRef<IModalHandlers>(null);

  // Define the steps of register
  const steps = [
    {
      label: 'Dados básicos',
      content: <BasicData formRef={formRefBasicData} />,
      validation: basicDataSchema,
      ref: formRefBasicData,
    },
    {
      label: 'Licenças / certificados',
      content: (
        <Grid columns="1fr" gap={24}>
          <Licenses formRef={formRefLicenses} />
          <Certificates formRef={formRefCertificates} />
        </Grid>
      ),
    },
    {
      label: 'Colaboradores',
      content: <Employees formRef={formRefEmployees} />,
    },
  ];

  const storeLicenses = async (providerId) => {
    try {
      const data = formRefLicenses.current?.getData();

      const responses = data?.licenses.map(async (license) => {
        const formData = new FormData();

        // format dates
        license.emission = format(license.emission, 'yyyy-MM-dd');
        license.expiration = format(license.expiration, 'yyyy-MM-dd');

        // default value
        license.type = 'operacao';

        // build formData with give values
        Object.keys(license).map((key) => {
          formData.append(key, license[key]);
        });

        const response = await api.providers.post(
          `/api/v1/providers/${providerId}/licenses`,
          formData
        );

        return response;
      });

      return Promise.allSettled(responses);
    } catch (exception) {
      throw new Error('Erro ao cadastrar as licenças!');
    }
  };

  const storeCertificates = async (providerId) => {
    try {
      const data = formRefCertificates.current?.getData();

      const responses = data?.certificates.map(async (certificate) => {
        const formData = new FormData();

        // format dates
        certificate.emission = format(certificate.emission, 'yyyy-MM-dd');

        // build formData with give values
        Object.keys(certificate).map((key) => {
          formData.append(key, certificate[key]);
        });

        const response = await api.providers.post(
          `/api/v1/providers/${providerId}/certificates`,
          formData
        );

        return response;
      });

      return Promise.allSettled(responses);
    } catch (exception) {
      throw new Error('Erro ao cadastrar os certificados!');
    }
  };

  const storeProvider = useCallback(async () => {
    const basicData = formRefBasicData.current?.getData();
    const employees = formRefEmployees.current?.getData();

    try {
      const formData = new FormData();

      // set providers' data
      formData.append('provider[alias]', basicData?.company.alias);
      formData.append('provider[activity]', basicData?.company.segment);

      basicData?.company?.types.map((type) => {
        formData.append('provider[types][][code]', type);
      });

      // build formData with give values
      Object.keys(basicData?.company).map((key) => {
        basicData?.company[key] &&
          formData.append(`company[${key}]`, basicData?.company[key]);
      });

      Object.keys(basicData?.responsible_first).map((key) => {
        basicData?.responsible_first[key] &&
          formData.append(
            `responsible[0][${key}]`,
            basicData?.responsible_first[key]
          );
      });

      Object.keys(basicData?.responsible_second).map((key) => {
        basicData?.responsible_second[key] &&
          formData.append(
            `responsible[1][${key}]`,
            basicData?.responsible_second[key]
          );
      });

      // run array
      Object.keys(employees?.employees).map((key) => {
        const employee = employees?.employees[key];

        // format dates
        employee.moop_expiration = format(
          employee.moop_expiration,
          'yyyy-MM-dd'
        );

        employee.license_expiration = format(
          employee.license_expiration,
          'yyyy-MM-dd'
        );

        Object.keys(employee).map((innerKey) => {
          employee.license_expiration &&
            formData.append(
              `employee[${key}][${innerKey}]`,
              employee[innerKey]
            );
        });
      });

      const response = await api.providers.post(`/api/v1/providers`, formData);

      const { uuid_provider } = response.data.provider;

      return uuid_provider;
    } catch (exception: any) {
      if (exception?.status === 409) {
        const doc_type = basicData?.company?.registry_type;

        throw new Error(
          `Um prestador ja foi cadastrado com o ${doc_type} informado`
        );
      }

      throw new Error('Erro ao cadastrar o prestador!');
    }
  }, []);

  // When finish the steps
  const onFinish = useCallback(async () => {
    try {
      nextButtonRef.current?.startLoad();

      const storeProviderResponse = await storeProvider();

      await storeLicenses(storeProviderResponse);
      await storeCertificates(storeProviderResponse);

      toast.success('Cadastro efetuado com sucesso!');
      history.push('/prestadores');
    } catch (exception: any) {
      toast.error(exception?.message || 'Erro ao cadastrar o prestador.');
    } finally {
      nextButtonRef.current?.finishLoad();
    }
  }, [history, storeProvider]);

  // Functions and variables to control the form's state
  const { currentStep, isFirstStep, isLastStep, nextStep, previousStep } =
    useStepFormControl({
      steps,
      onFinish,
    });

  return (
    <>
      <ModalWrapper ref={modalRef}>
        <AlertModal />
      </ModalWrapper>
      <Container>
        <Head>
          <HeadContent>
            <Title variant="header">Cadastrar Novo Prestador</Title>
            <Text className="helper">(*) campos obrigatórios</Text>
          </HeadContent>
        </Head>
        <Content>
          <Stepper
            current={currentStep}
            steps={steps.map(({ label }) => label)}
          />
          <Grid columns="1fr" gap={24}>
            {steps.map(({ content }, index) => (
              <Can key={index.toString()} see={currentStep === index}>
                {content}
              </Can>
            ))}
            <Controls>
              <Button
                label="Cancelar"
                variant="outline"
                value="cancel"
                onClick={() => modalRef.current?.open()}
                icon={Cancel}
                iconPosition="after"
                divider
              />
              <Button
                label="Voltar"
                variant="outline"
                value="previous"
                disabled={isFirstStep}
                onClick={previousStep}
                icon={Prev}
                iconPosition="after"
                divider
              />
              <Button
                ref={nextButtonRef}
                label={isLastStep ? 'Salvar' : 'Próximo'}
                value="next"
                onClick={nextStep}
                icon={isLastStep ? Save : Next}
                iconPosition="after"
                divider
              />
            </Controls>
          </Grid>
        </Content>
      </Container>
    </>
  );
};

export default Store;
