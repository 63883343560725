import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px;
  border-radius: 12px;
  background-color: #ebf0fa;
  box-shadow: 0px 1px 2px #174da380;
`;

export const Value = styled.div`
  color: #759fe3;
  font-size: 2.625rem;
`;

export const Text = styled.div`
  color: #174da3;
  font-weight: 500;
  font-size: 1rem;
  max-width: 70%;
`;
