import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const Name = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
`;

export const Control = styled.div``;
