// import { FC, useEffect, useRef } from 'react';

// import { SubmitHandler, FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
// import { useHistory, useLocation } from 'react-router-dom';
// import Button, { IButtonHandlers } from 'src/components/Button';
// import Input from 'src/components/Input';
// import Title from 'src/components/Title';
// import { defaultOptions, formatErrors } from 'src/libs/yup';
// import api from 'src/services/api';
// import getApiErrors from 'src/utils/getApiErrors';
// import forgotMyPasswordSchema from 'src/validators/forgotMyPassword.schema';

// import {
//   BackToLoginContainer,
//   BackToLoginLink,
//   Container,
//   Content,
// } from './styles';

// interface FormData {
//   email: string;
// }

// const ForgotMyPassword: FC = () => {
//   const buttonRef = useRef<IButtonHandlers>(null);
//   const formRef = useRef<FormHandles>(null);

//   const history = useHistory();
//   const location = useLocation<{
//     email?: string;
//   }>();

//   useEffect(() => {
//     if (formRef.current && location?.state?.email) {
//       formRef.current?.setFieldValue('email', location.state.email);
//     }
//   }, [location]);

//   const forgotRequest = async (data: FormData) => {
//     try {
//       await api.user.post('/admin/forgot-password', data);

//       history.push('/esqueci-minha-senha/info', data);
//     } catch (error) {
//       const errors = getApiErrors(error);

//       if (errors) formRef.current?.setErrors(errors);

//       if (error?.response?.status === 404) {
//         formRef.current?.setFieldError(
//           'email',
//           'O e-mail não está cadastrado!'
//         );
//       }

//       buttonRef.current?.finishLoad();
//     }
//   };

//   const handleOnSubmit: SubmitHandler<FormData> = async (formData) => {
//     buttonRef.current?.startLoad();
//     formRef.current?.setErrors({});

//     try {
//       const data: FormData = await forgotMyPasswordSchema.validate(
//         formData,
//         defaultOptions
//       );

//       forgotRequest(data);
//     } catch (errors) {
//       if (errors?.inner) {
//         formRef.current?.setErrors(formatErrors(errors.inner));
//       }

//       buttonRef.current?.finishLoad();
//     }
//   };

//   return (
//     <Container>
//       <Content>
//         <Title variant="title">Esqueci minha senha</Title>
//         <Form ref={formRef} onSubmit={handleOnSubmit}>
//           <Input id="email" label="E-mail" name="email" type="email" />
//           <Button ref={buttonRef} label="Enviar" type="submit" />
//         </Form>
//         <BackToLoginContainer>
//           <BackToLoginLink to="/">Voltar para o login</BackToLoginLink>
//         </BackToLoginContainer>
//       </Content>
//     </Container>
//   );
// };

// export default ForgotMyPassword;
import { FC, useRef, useState, useEffect } from 'react';

import getApiErrors from 'src/utils/getApiErrors';
import { ToastContainer, toast } from 'react-toastify';
import logo from 'src/assets/img/logo-pv.png';
import arte from 'src/assets/img/arte-login.png';
import sso from 'src/assets/img/logo-sso.png';
import api from 'src/services/api';
import lock from 'src/assets/img/ico-lock.png';
import user from 'src/assets/img/ico-user.png';
import visibility from 'src/assets/img/visibility.png';
import view from 'src/assets/img/view.png';
import InputLogin from 'src/components/InputLogin';
import BockInput from 'src/components/BockInput';
import ButtonLogin from 'src/components/ButtonLogin';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import { GrennRedef, RedRedef } from 'src/components/Icons';
import Input from 'src/components/Input';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import keys from 'src/services/keys';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import {
  Container,
  Logo,
  Form,
  LeftBox,
  RightBox,
  LogoRight,
  TextEntre,
  TextFooter,
  ImageSSO,
  ImageLock,
  ImageUser,
  TextORSSO,
  HRORSSOs,
  MenuItemLink,
  TextTitle,
  PasswordItem,
  ReCaptcha,
  PasswordItemTitle,
  IconPass,
  Title,
} from './styles';

const RecriarSenha = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loading = false;
    dispatch(AuthActions.authLoading(loading));
  }, []);
  const { id } = useParams<{ id: string }>();
  const { tokenPass } = useParams<{ tokenPass: string }>();

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const history = useHistory();
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [show_input, setShowInput] = useState<boolean>(false);
  const [show_input_confirm, setShowInputConfirm] = useState<boolean>(false);

  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
  const [showPass, setShowPass] = useState<boolean>(true);

  const [oito_caracteres, setOitoCaracteres] = useState<boolean>(false);
  const [um_numero, setUmNumero] = useState<boolean>(false);
  const [um_caractere_especial, setUmCaractereEspecial] =
    useState<boolean>(false);
  const [uma_letra, setUmaLetra] = useState<boolean>(false);
  const [uma_maiuscula, setUmaMaiuscula] = useState<boolean>(false);
  const [uma_minuscula, setUmaMinuscula] = useState<boolean>(false);
  const [nova_senha_confirm, setNovaSenhaConfirm] = useState<boolean>(false);
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [recaptcha_privateke, setRecaptcha_privateke] = useState<any>(
    keys.REACT_APP_SITE_KEY
  );

  const changePassword = (password: string) => {
    const numeros = /([0-9])/;
    const alfabeto = /([a-zA-Z])/;
    const maiuscula = /[A-Z]/;
    const minuscula = /([a-z])/;
    const chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

    if (password.length < 8) {
      setOitoCaracteres(false);
    } else {
      setOitoCaracteres(true);
    }

    if (!password.match(maiuscula)) {
      setUmaMaiuscula(false);
    } else {
      setUmaMaiuscula(true);
    }

    if (!password.match(minuscula)) {
      setUmaMinuscula(false);
    } else {
      setUmaMinuscula(true);
    }

    if (!password.match(chEspeciais)) {
      setUmCaractereEspecial(false);
    } else {
      setUmCaractereEspecial(true);
    }

    if (!password.match(numeros)) {
      setUmNumero(false);
    } else {
      setUmNumero(true);
    }

    if (!password.match(alfabeto)) {
      setUmaLetra(false);
    } else {
      setUmaLetra(true);
    }

    if (passwordConfirm != password) {
      setNovaSenhaConfirm(false);
    } else {
      if (passwordConfirm == '' && password == '') {
        setNovaSenhaConfirm(false);
      } else {
        setNovaSenhaConfirm(true);
      }
    }

    setPassword(password);
    funcErrorPassword(false);
  };

  const changePasswordConfirm = (passwordConfirm: string) => {
    if (passwordConfirm != password) {
      setNovaSenhaConfirm(false);
    } else {
      if (passwordConfirm == '' && password == '') {
        setNovaSenhaConfirm(false);
      } else {
        setNovaSenhaConfirm(true);
      }
    }

    setPasswordConfirm(password);
    funcErrorPasswordConfirm(false);
  };

  const funcErrorPasswordConfirm = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const funcPassView = (passview: boolean) => {
    setShowPass(!showPass);
    setShowInput(!show_input);
  };

  const funcPassViewConfirm = (passview: boolean) => {
    setShowPassConfirm(!showPassConfirm);
    setShowInputConfirm(!show_input_confirm);
  };

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
  };

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
    setErrorPassword(false);
  };

  const backLogin = () => {
    history.push('/');
  };

  const forgotRequest = async () => {
    if (!oito_caracteres) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter no mínimo 8 caracteres ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!um_numero) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 número ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!um_caractere_especial) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 caractere especial ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!uma_letra) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 letra ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!uma_maiuscula) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 letra maiúscula ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!nova_senha_confirm) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Senhas não podem ser diferentes ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (recaptchavalue == '') {
      await setErrorPassword(true);
      toast.error('Validação do reCAPCHA é obrigatória!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    redefinirPass();
    // history.push('/');
  };
  const redefinirPass = async () => {
    if (!oito_caracteres) {
      funcErrorPassword(true);
    } else {
      if (!um_numero) {
        funcErrorPassword(true);
      } else {
        if (!um_caractere_especial) {
          funcErrorPassword(true);
        } else {
          if (!uma_letra) {
            funcErrorPassword(true);
          } else {
            if (!uma_maiuscula) {
              funcErrorPassword(true);
            } else {
              if (!nova_senha_confirm) {
                funcErrorPassword(true);
              } else {
                if (recaptchavalue == '') {
                  funcErrorPassword(true);
                } else {
                  const data = {
                    token: tokenPass,
                    id: id,
                    passwordNew: password,
                    passwordNewConfirm: passwordConfirm,
                  };
                  try {
                    const loading = true;
                    dispatch(AuthActions.authLoading(loading));
                    const response = await api.user.post(
                      '/api/v1/users/password',
                      data
                    );
                    funcErrorPassword(false);
                    history.push('/sucesso/troca_senha');
                  } catch (error) {
                    const loading = false;
                    dispatch(AuthActions.authLoading(loading));
                    funcErrorPassword(false);
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <Container>
      <Form>
        <LeftBox>
          <TextEntre>Bem-vindo à Revolução</TextEntre>

          <Logo>
            <img src={logo} alt="Plataforma Verde" />
          </Logo>

          <HRORSSOs>
            <TextORSSO>REDEFINIR SENHA DE ACESSO</TextORSSO>
          </HRORSSOs>
          <BockInput error={errorPassword}>
            <ImageUser>
              <img src={lock} alt="Plataforma Verde" />
            </ImageUser>
            <InputLogin
              type={show_input ? 'text' : 'password'}
              placeholder="Cadastrar senha de acesso"
              onChange={(e) => changePassword(e.target.value)}
            />
            <ImageUser onClick={() => funcPassView(showPass)}>
              <img
                src={showPass ? visibility : view}
                style={{
                  position: 'absolute',
                  width: '8%',
                  float: 'left',
                  right: '8px',
                }}
                alt="Plataforma Verde"
              />
            </ImageUser>
          </BockInput>

          <BockInput error={errorPassword}>
            <ImageUser>
              <img src={lock} alt="Plataforma Verde" />
            </ImageUser>
            <InputLogin
              type={show_input_confirm ? 'text' : 'password'}
              placeholder="Confirmar senha de acesso"
              onChange={(e) => changePasswordConfirm(e.target.value)}
            />
            <ImageUser onClick={() => funcPassViewConfirm(showPassConfirm)}>
              <img
                src={showPassConfirm ? visibility : view}
                style={{
                  position: 'absolute',
                  width: '8%',
                  float: 'left',
                  right: '8px',
                }}
                alt="Plataforma Verde"
              />
            </ImageUser>
          </BockInput>
          <ReCaptcha>
            <ReCAPTCHA
              sitekey={recaptcha_privateke}
              size="normal"
              badge="inline"
              onChange={(e) => changeRecaptha(e)}
              width={100}
            />
          </ReCaptcha>

          <TextTitle>
            Você de atender a todos os requisitos para criação de senha
            descritos abaixo para continuar.
          </TextTitle>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {oito_caracteres && <GrennRedef style={{ width: 14 }} />}
                {!oito_caracteres && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Ter no mínimo 8 caracteres
            </PasswordItemTitle>
          </PasswordItem>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {um_numero && <GrennRedef style={{ width: 14 }} />}
                {!um_numero && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Ter pelo menos 1 número
            </PasswordItemTitle>
          </PasswordItem>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {um_caractere_especial && <GrennRedef style={{ width: 14 }} />}
                {!um_caractere_especial && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Ter pelo menos 1 caractere especial
            </PasswordItemTitle>
          </PasswordItem>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {uma_letra && <GrennRedef style={{ width: 14 }} />}
                {!uma_letra && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Ter pelo menos 1 letra
            </PasswordItemTitle>
          </PasswordItem>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {uma_maiuscula && <GrennRedef style={{ width: 14 }} />}
                {!uma_maiuscula && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Ter pelo menos 1 letra maiúscula
            </PasswordItemTitle>
          </PasswordItem>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {uma_minuscula && <GrennRedef style={{ width: 14 }} />}
                {!uma_minuscula && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Ter pelo menos 1 letra minúscula
            </PasswordItemTitle>
          </PasswordItem>

          <PasswordItem>
            <PasswordItemTitle>
              <IconPass>
                {nova_senha_confirm && <GrennRedef style={{ width: 14 }} />}
                {!nova_senha_confirm && <RedRedef style={{ width: 14 }} />}
              </IconPass>{' '}
              Nova senha confirmada
            </PasswordItemTitle>
          </PasswordItem>

          <ButtonLogin
            style={{ marginBottom: 15 }}
            onClick={() => forgotRequest()}
            type="button"
          >
            REDEFINIR
          </ButtonLogin>
        </LeftBox>

        <RightBox>
          <LogoRight>
            <img src={arte} alt="Plataforma Verde" />
          </LogoRight>
        </RightBox>
      </Form>
    </Container>
  );
};

export default RecriarSenha;
