import styled, { css } from 'styled-components';

import { ThemeType } from 'src/styles/themes/light';

interface ButtonThemeProps {
  color: string;
  variant: string;
  theme?: ThemeType;
}

const getBase = (theme, variant) => css`
  border-radius: 5px;
  height: 40px;
  padding: 0px 24px;

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled .button-divider {
    background-color: ${variant === 'outline'
      ? theme?.button.disabled.border
      : theme?.button.disabled.foreground};
  }

  &:not(.loading):disabled {
    color: ${variant === 'outline'
      ? theme?.button.disabled.border
      : theme?.button.disabled.foreground};
    background-color: ${variant === 'outline'
      ? 'transparent'
      : theme?.button.disabled.background};
    border: 1px solid ${theme?.button.disabled.border};

    svg {
      color: ${theme?.button.disabled.border};
      fill: ${theme?.button.disabled.border};
    }
  }
`;

export const Container = styled.button`
  font-size: 0.9375rem; // 15px
  font-weight: 400;
  user-select: none;

  /* text-transform: uppercase; */
  transition: background-color 200ms linear, border-color 200ms linear;

  &.button-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  &.icon-after {
    display: flex;
    gap: 10px;
    padding: 0;

    width: 160px;
    height: 40px;

    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.icon-after svg {
    width: 22px;
    height: 22px;
  }

  &.icon-after span:first-child {
    margin-right: auto;
    padding-left: 20%;
    align-self: center;
  }

  &.icon-before {
    display: flex;
    gap: 10px;
    padding: 0;
    align-content: flex-start;

    width: 160px;
    height: 40px;

    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.icon-before span:first-child {
    margin-right: auto;
    padding-left: 0;
    align-self: center;
  }

  & .button-divider {
    align-self: stretch;
    margin: 4px 0;
    width: 1px;
    box-sizing: border-box;
  }

  ${({ color, theme, variant }: ButtonThemeProps) => {
    if (variant === 'line') {
      return css`
        &[color] {
          color: ${theme?.button.line[color].foreground};
          background: none;
          border: none;

          svg {
            color: ${theme?.button.line[color].foreground};
          }

          & .button-divider {
            background-color: #5cac21;
          }

          :hover {
            color: ${theme?.button.line[color].hover.foreground};

            svg {
              fill: ${theme?.button.line[color].hover.foreground};
            }
          }
        }
      `;
    }

    if (variant === 'normal') {
      return css`
        &[color] {
          color: ${theme?.button.normal[color].foreground};
          background-color: ${theme?.button.normal[color].background};
          border: 1px solid ${theme?.button.normal[color].border};

          svg {
            fill: ${theme?.button.normal[color].foreground};
          }

          & .button-divider {
            background-color: #ffffff;
          }

          :hover {
            color: ${theme?.button.normal[color].hover.foreground};
            background-color: ${theme?.button.normal[color].hover.background};
            border-color: ${theme?.button.normal[color].hover.border};

            svg {
              fill: ${theme?.button.normal[color].hover.foreground};
            }
          }
        }

        ${getBase(theme, variant)};
      `;
    }

    if (variant === 'table-inclusion') {
      return css`
        &[color] {
          color: ${theme?.button.normal[color].foreground};
          background-color: ${theme?.button.normal[color].background};
          border: 1px solid ${theme?.button.normal[color].border};
          width: 100px;
          height: 30px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          padding: 0 13px;
          display: flex;
          align-items: center;
          align-content: center;

          svg {
            color: ${theme?.button.normal[color].foreground};
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }

          & .button-divider {
            background-color: ${theme?.button.normal[color].hover.foreground};
          }

          :hover {
            color: ${theme?.button.normal[color].hover.foreground};
            background-color: ${theme?.button.normal[color].hover.background};
            border-color: ${theme?.button.normal[color].hover.border};

            & .button-divider {
              background-color: ${theme?.button.normal[color].hover.foreground};
            }

            svg {
              fill: ${theme?.button.normal[color].hover.foreground};
            }
          }
        }

        ${getBase(theme, variant)};
      `;
    }

    // Outline
    return css`
      color: ${theme?.button.outline[color].foreground};
      background-color: ${theme?.button.outline[color].background};
      border: 1px solid ${theme?.button.outline[color].border};

      svg {
        fill: ${theme?.button.outline[color].foreground};
      }

      & .button-divider {
        background-color: ${theme?.button.outline[color].foreground};
      }

      :hover {
        color: ${theme?.button.outline[color].hover.foreground};
        background-color: ${theme?.button.outline[color].hover.background};
        border-color: ${theme?.button.outline[color].hover.border};

        .button-divider {
          background-color: ${theme?.button.outline[color].hover.foreground};
        }

        svg {
          fill: ${theme?.button.outline[color].hover.foreground};
        }
      }

      ${getBase(theme, variant)};
    `;
  }}
`;

export const Span = styled.span`
  pointer-events: none;
`;
