import { InputHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import InputErrorMessage from 'src/components/InputErrorMessage';
import Label from 'src/components/Label';

import { Clip } from '../Icons';
import { Container, DisplayField, FieldContainer, FileField } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  required?: boolean | undefined;
}

const InputFile = ({
  accept,
  label,
  name,
  required,
  placeholder,
}: InputProps): JSX.Element => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputDisplayRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    if (inputFileRef.current)
      registerField({
        name: fieldName,
        ref: inputFileRef.current,
        path: 'files[0]',
        clearValue(ref) {
          ref.value = '';
        },
        setValue(ref, value) {
          if (inputDisplayRef.current) {
            inputDisplayRef.current.value = '';
          }

          return null;
        },
      });
  }, [inputDisplayRef, fieldName, registerField]);

  const handleOnChange = (event) => {
    const file = event.target.files?.[0];
    if (inputDisplayRef.current) {
      if (file) {
        inputDisplayRef.current.value = file.name;
      } else {
        inputDisplayRef.current.value = '';
      }
    }
  };

  const handleOnClick = () => inputFileRef.current?.click();

  return (
    <Container>
      <Label htmlFor={fieldName}>
        {label}
        {required === true ? '*' : ''}
      </Label>
      <FieldContainer>
        <Clip />
        <DisplayField
          ref={inputDisplayRef}
          onClick={handleOnClick}
          placeholder={placeholder}
          readOnly
        />
        <FileField
          ref={inputFileRef}
          type="file"
          name={fieldName}
          accept={accept}
          onChange={handleOnChange}
        />
      </FieldContainer>
      <InputErrorMessage
        fieldLabel={label}
        fieldName={fieldName}
        message={error}
      />
    </Container>
  );
};

export default InputFile;
