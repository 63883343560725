import { useHistory } from 'react-router';
import { Legislation } from 'src/components/Icons';

import { Container, LegislationIcon } from './styles';

const Central = (): JSX.Element => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/legislacao');
  };
  return (
    <Container>
      <LegislationIcon onClick={() => handleClick()}>
        <Legislation />
      </LegislationIcon>
      <div />
      <div />
      <div />
    </Container>
  );
};

export default Central;
