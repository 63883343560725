import ForgotMyPassword from './ForgotMyPassword';
import ChooseCompany from './ChooseCompany';
import ChooseModule from './ChooseModule';
import ErroInatividade from './ErroInatividade';
import ErroJaLogado from './ErroJaLogado';
import ForgotMyPasswordInfo from './ForgotMyPasswordInfo';
import Login from './Login';
import LoginSSO from './LoginSSO';
import RecriarSenha from './RecriarSenha';
import RecriarSenhaForte from './RecriarSenhaForte';
import SucessoTrocaSenha from './SucessoTrocaSenha';
import PrimeiroAcesso from './PrimeiroAcesso';

import RecoverPassword from './RecoverPassword';

export default {
  ChooseCompany,
  ChooseModule,
  ErroInatividade,
  ErroJaLogado,
  RecriarSenha,
  RecriarSenhaForte,
  SucessoTrocaSenha,
  PrimeiroAcesso,
  ForgotMyPassword,
  ForgotMyPasswordInfo,
  Login,
  LoginSSO,
  RecoverPassword,
};
