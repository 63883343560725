import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'src/store/ducks/index';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as AuthActions } from 'src/store/ducks/auth';

import { Container } from './styles';

const Company = (): JSX.Element => {
  const { companySelected } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const [company, setCompany] = useState('');

  const dispatch = useDispatch();
  const RedirectLogin = () => {
    history.push('/');
  };
  useEffect(() => {
    const companyName = async () => {
      try {
        if (
          companySelected[0]?.company_name !== '' &&
          companySelected[0]?.company_name !== undefined
        ) {
          setCompany(companySelected[0]?.company_name);
        } else {
          dispatch(AuthActions.authLogout());
          RedirectLogin();
        }
      } catch (error) {
        dispatch(AuthActions.authLogout());
        RedirectLogin();
      }
    };
    companyName();
  }, [companySelected]);

  return <Container>{company}</Container>;
};

export default Company;
