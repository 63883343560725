import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 24px);
  grid-gap: 16px;

  height: 25px;

  div {
    height: 100%;
    width: 100%;
    border-radius: 50%;

    align-self: center;
    justify-self: center;

    border: 1px dashed white;
  }
`;

export const LegislationIcon = styled.div`
  width: 25;
  height: 24;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  margin-left: 10;
  cursor: 'pointer';
  border-radius: 7px;
  background-color: '#327118';
  border: none !important;
  cursor: pointer;
`;
