import styled from 'styled-components';

export const Container = styled.div`
  height: 140vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(119deg, #92e8c5 0%, #98af6c 100%) 0%
    0% no-repeat padding-box;
`;
export const ColorGreen = styled.span`
  font-weight: bold;
  color: #56ad39;
`;

export const ReCaptcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
`;

export const CheckBoxBody = styled.div`
  input:checked {
    background: red;
  }
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 300;
  color: #6f6f6f;
  margin-bottom: 6%;
`;

export const PasswordItemTitle = styled.p`
  font-size: 12px !important;
  margin-bottom: 20px !important;
  text-align: left !important;
  color: #606060 !important;
`;

export const IconPass = styled.span`
  float: left;
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: 0px 7px !important;
  height: 20px !important;
  width: 10px !important;
  margin-right: 10px !important;
`;

export const PasswordItem = styled.div`
  width: 100%;
  padding-left: 48px !important;
`;

export const MenuItemLink = styled.a`
  color: #3b5998;
  text-decoration: none;
  justify-content: center;
  margin: 7px 0;
  display: flex;
  align-items: center;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  > svg {
    font-size: 18px;
    margin-right: 5px;
  }
`;

export const HrOrSSO = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const ImageLock = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const ImageUser = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const TextTitle = styled.p`
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #6f6f6f;
  margin-top: 5px;
  font-weight: 300;
  color: #6f6f6f;
  margin-bottom: 6%;
`;

export const Title = styled.p`
  text-align: center;
  font-size: 13px;
  letter-spacing: 0px;
  color: #6f6f6f;
  font-weight: bold;
`;

export const ImageSSO = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0%;

  > img {
    width: 60%;
    height: auto;
  }
`;

export const LeftBox = styled.div`
  width: 39%;
  justify-content: center;
  align-items: center;
`;

export const RightBox = styled.div`
  width: 65%;
  padding-left: 20px;
  padding-rigth: 15px;
  justify-content: center;
  align-items: center;
`;

export const BockInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
`;

export const Form = styled.form`
  width: 60%;
  display: flex;
  padding: 30px;
  padding-right: 10px;
  padding-bottom: 0;
  border-radius: 10px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

export const TextEntre = styled.p`
  text-align: center;
  font: normal normal normal 13px/24px Roboto;
  color: #909090;
  opacity: 1;
  margin-bottom: 5px;
`;

export const TextORSSO = styled.p`
  text-align: center;
  font: normal normal normal 11px/24px Roboto;
  color: #909090;
  opacity: 1;
  margin-bottom: 5px;
  background: #ffffff;
  position: absolute;
  padding: 15px;
  margin-top: 8px;
`;

export const HRORSSOs = styled.div`
  width: 100%;
  border-bottom: 1px solid #56ad39;
  margin-top: 12%;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  margin-bottom: 10%;
`;

export const TextFooter = styled.p`
  text-align: center;
  font: normal normal normal 10px/24px Roboto;
  color: #606060;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 7%;
  margin-bottom: 5%;
`;

export const LogoRight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: 40px;

  color: #ffffff;

  &:after {
    content: '';
    display: block;
    width: 55px;
    border-bottom: 10px solid #ff6961;
  }
`;
