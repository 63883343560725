import { FC, useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Title from 'src/components/Title';
import { Creators as AuthActions } from 'src/store/ducks/auth';

import {
  BackToLoginContainer,
  BackToLoginLink,
  Container,
  Content,
} from './styles';

const Logout = (): JSX.Element => {
  const history = useHistory();

  const dispatch = useDispatch();

  const RedirectLogin = () => {
    history.push('/');
  };

  useEffect(() => {
    let timer: any = undefined;

    const typing = () => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        dispatch(AuthActions.authLogout());
        RedirectLogin();
      }, 1500);
    };
    typing();
  });

  return (
    <Container>
      <Content>
        <Title variant="title">Logout Até mais!</Title>
        <BackToLoginContainer>
          <BackToLoginLink to="/">Voltar para o login</BackToLoginLink>
        </BackToLoginContainer>
      </Content>
    </Container>
  );
};

export default Logout;
