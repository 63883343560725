import { ComponentType, FC, HTMLAttributes } from 'react';

import FormGroup from '../FormGroup';
import { Container, Content } from './styles';

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  children: string | any[];
  icon?: ComponentType;
  className?: string;
  variant?: 'display' | 'header' | 'title' | 'subtitle' | 'headline' | 'info';
}

const Title: FC<TitleProps> = ({
  children,
  icon: Icon,
  className = '',
  variant = 'title',
  ...props
}) => {
  return (
    <Container className={`root-title ${variant} ${className}`} {...props}>
      <Content columns={Icon ? '0.1fr 15fr' : '1fr'} gap={10}>
        {Icon && <Icon />}
        <span>{children}</span>
      </Content>
    </Container>
  );
};

export default Title;
