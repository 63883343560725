import { useHistory } from 'react-router';
import Button from 'src/components/Button';
import { Confirm } from 'src/components/Icons';
import { IModalContentProps } from 'src/components/ModalWrapper';

import { Body, Container, Footer, Head, Instruction } from './styles';

const AlertModal = ({ closeButton }: IModalContentProps): JSX.Element => {
  const history = useHistory();

  const handleOnClickOk = () => history.goBack();

  return (
    <Container>
      <Head>Aviso! {closeButton}</Head>
      <Body>
        <Instruction>
          Ao cancelar o processo de cadastro você perderá os dados já
          preenchidos!
        </Instruction>
      </Body>
      <Footer>
        <Button
          label="Confirmar"
          icon={Confirm}
          iconPosition="after"
          onClick={handleOnClickOk}
          divider
        />
      </Footer>
    </Container>
  );
};

export default AlertModal;
