import styled from 'styled-components';

export const Container = styled.div`
  padding: 14px 16px 14px 20px;

  background-position: center;
  background-size: cover;
  background-image: url('/assets/img/header_pattern.png');
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.75fr;
`;

export const LeftContent = styled.div``;

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
