import styled from 'styled-components';

import FormGroup from '../FormGroup';

export const Container = styled.div`
  color: ${({ theme }) => theme.text.title};

  align-content: center;

  & svg {
    width: 32px;
    height: 32px;
    fill: #56ad39;
  }

  &.display {
    font-size: 2.625rem;
    font-weight: 800;
  }

  &.header {
    font-size: 30px;
    font-weight: 400;
  }

  &.title {
    font-size: 1.75rem;
    font-weight: 800;
  }

  &.subtitle {
    font-family: 'Roboto Condensed';
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.headline {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &.info {
    display: inline-block;
    margin-left: 5px;

    font-size: 13px;
    text-transform: lowercase;
  }
`;

export const Content = styled(FormGroup)`
  align-items: end;
`;
