import { useHistory } from 'react-router';
import { DropdownArrowRight } from 'src/components/Icons';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
} from './styles';

interface IDropdownSubmenus {
  show?: boolean;
  label: string;
  path: string;
}

interface IDropdownProps {
  icon: JSX.Element;
  active?: boolean;
  path?: string;
  submenus?: {
    label: string;
    paths: IDropdownSubmenus[];
  };
}

const Dropdown = ({
  active = false,
  icon,
  submenus,
  path,
}: IDropdownProps): JSX.Element => {
  const history = useHistory();
  const handleOnClickButton = () => {
    if (path) history.push(path);
  };

  return (
    <Container className={submenus && 'dropdown'}>
      <Button
        active={active ? true : undefined}
        hasAction={!!(submenus || path)}
        onClick={handleOnClickButton}
      >
        {icon}
      </Button>
      {submenus && <DropdownArrowRight />}
      {submenus && (
        <DropContainer className="drop">
          <DropContent>
            <DropTitle>{submenus.label}</DropTitle>
            <DropLinkContainer>
              {submenus.paths.map(
                (item, index) =>
                  item.show === undefined && (
                    <DropLink key={index.toString()} to={item.path}>
                      {item.label}
                    </DropLink>
                  )
              )}
            </DropLinkContainer>
          </DropContent>
        </DropContainer>
      )}
    </Container>
  );
};

export default Dropdown;
