// import { FC, useEffect, useRef } from 'react';

// import { SubmitHandler, FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
// import { useHistory, useLocation } from 'react-router-dom';
// import Button, { IButtonHandlers } from 'src/components/Button';
// import Input from 'src/components/Input';
// import Title from 'src/components/Title';
// import { defaultOptions, formatErrors } from 'src/libs/yup';
// import api from 'src/services/api';
// import getApiErrors from 'src/utils/getApiErrors';
// import forgotMyPasswordSchema from 'src/validators/forgotMyPassword.schema';

// import {
//   BackToLoginContainer,
//   BackToLoginLink,
//   Container,
//   Content,
// } from './styles';

// interface FormData {
//   email: string;
// }

// const ForgotMyPassword: FC = () => {
//   const buttonRef = useRef<IButtonHandlers>(null);
//   const formRef = useRef<FormHandles>(null);

//   const history = useHistory();
//   const location = useLocation<{
//     email?: string;
//   }>();

//   useEffect(() => {
//     if (formRef.current && location?.state?.email) {
//       formRef.current?.setFieldValue('email', location.state.email);
//     }
//   }, [location]);

//   const forgotRequest = async (data: FormData) => {
//     try {
//       await api.user.post('/admin/forgot-password', data);

//       history.push('/esqueci-minha-senha/info', data);
//     } catch (error) {
//       const errors = getApiErrors(error);

//       if (errors) formRef.current?.setErrors(errors);

//       if (error?.response?.status === 404) {
//         formRef.current?.setFieldError(
//           'email',
//           'O e-mail não está cadastrado!'
//         );
//       }

//       buttonRef.current?.finishLoad();
//     }
//   };

//   const handleOnSubmit: SubmitHandler<FormData> = async (formData) => {
//     buttonRef.current?.startLoad();
//     formRef.current?.setErrors({});

//     try {
//       const data: FormData = await forgotMyPasswordSchema.validate(
//         formData,
//         defaultOptions
//       );

//       forgotRequest(data);
//     } catch (errors) {
//       if (errors?.inner) {
//         formRef.current?.setErrors(formatErrors(errors.inner));
//       }

//       buttonRef.current?.finishLoad();
//     }
//   };

//   return (
//     <Container>
//       <Content>
//         <Title variant="title">Esqueci minha senha</Title>
//         <Form ref={formRef} onSubmit={handleOnSubmit}>
//           <Input id="email" label="E-mail" name="email" type="email" />
//           <Button ref={buttonRef} label="Enviar" type="submit" />
//         </Form>
//         <BackToLoginContainer>
//           <BackToLoginLink to="/">Voltar para o login</BackToLoginLink>
//         </BackToLoginContainer>
//       </Content>
//     </Container>
//   );
// };

// export default ForgotMyPassword;
import React, { useState, useEffect } from 'react';

import getApiErrors from 'src/utils/getApiErrors';
import { ToastContainer, toast } from 'react-toastify';
import logo from 'src/assets/img/logo-pv.png';
import arte from 'src/assets/img/arte-login.png';
import sso from 'src/assets/img/logo-sso.png';
import api from 'src/services/api';
import lock from 'src/assets/img/ico-lock.png';
import user from 'src/assets/img/ico-user.png';
import InputLogin from 'src/components/InputLogin';
import BockInput from 'src/components/BockInput';
import ButtonLogin from 'src/components/ButtonLogin';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as AuthActions } from 'src/store/ducks/auth';

import {
  Container,
  Logo,
  Form,
  LeftBox,
  RightBox,
  LogoRight,
  TextEntre,
  TextFooter,
  ImageSSO,
  ImageLock,
  ImageUser,
  TextORSSO,
  HRORSSOs,
  MenuItemLink,
  TextTitle,
  Title,
} from './styles';

const ForgotMyPasswordInfo: React.FC = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loading = false;
    dispatch(AuthActions.authLoading(loading));
  }, []);

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const history = useHistory();

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
  };

  const backLogin = () => {
    history.push('/');
  };

  const forgotRequest = async () => {
    history.push('/');
  };

  return (
    <Container>
      <Form>
        <LeftBox>
          <TextEntre>Bem-vindo à Revolução</TextEntre>

          <Logo>
            <img src={logo} alt="Plataforma Verde" />
          </Logo>

          <HRORSSOs>
            <TextORSSO>REDEFINIR SENHA DE ACESSO</TextORSSO>
          </HRORSSOs>
          <Title>Link enviado com sucesso!</Title>
          <TextTitle>
            Enviamos uma mensagem com o link de redefinição de senha para seu
            e-mail. Verifique sua caixa de entrada.
          </TextTitle>

          <ButtonLogin onClick={() => forgotRequest()} type="button">
            VOLTAR AO INÍCIO
          </ButtonLogin>
        </LeftBox>

        <RightBox>
          <LogoRight>
            <img src={arte} alt="Plataforma Verde" />
          </LogoRight>
        </RightBox>
      </Form>
    </Container>
  );
};

export default ForgotMyPasswordInfo;
