import Title from 'src/components/Title';

import Indicators from './Indicators';
import { Container } from './styles';

const Dashboard = (): JSX.Element => {
  return (
    <Container>
      <Title variant="header">Painel de Controle</Title>
      <Indicators />
    </Container>
  );
};

export default Dashboard;
