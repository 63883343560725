import styled from 'styled-components';

interface IContainerProps {
  showModal: number;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  display: ${({ showModal }) => (showModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  background-color: #d32e2e;

  svg {
    height: 16px;
    color: #ffffff;
  }
`;
