/* eslint-disable react/display-name */
import { RefObject, useCallback, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { useParams } from 'react-router';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormModal from 'src/components/FormModal';
import IconButton from 'src/components/IconButton';
import { Inactive, Justification } from 'src/components/Icons';
import InactiveModal from 'src/components/InactiveModal';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import JustificationModal from 'src/components/JustificationModal';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Table from 'src/components/Table';
import Hover from 'src/components/Table/Hover';
import Actions from 'src/components/Table/Hover/Actions';
import Title from 'src/components/Title';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import api from 'src/services/api';
import { certificatesSchema } from 'src/validators/providers/store/index';
interface ICertificatesProps {
  formRef: RefObject<FormHandles>;
}

interface IDataState {
  data: { [key: string]: any }[];
  total: number;
}

const Certificates = ({ formRef }: ICertificatesProps): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const buttonRef = useRef<IButtonHandlers>(null);
  const modalFormRef = useRef<IModalHandlers>(null);
  const justificationsModalRef = useRef<IModalHandlers>(null);
  const inactiveModalRef = useRef<IModalHandlers>(null);

  const [apiURL, setApiURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDataState>({
    data: [],
    total: 0,
  });

  const getCertificates = useCallback(
    async ({ pageIndex = 0, pageSize = 10, showInactive = false }) => {
      try {
        setLoading(true);
        const response = await api.providers.get(
          `/api/v1/providers/${id}/certificates?page=${
            pageIndex + 1
          }&per_page=${pageSize}&active=${!showInactive}`
        );

        setData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  const handleOnClickNew = () => {
    modalFormRef.current?.open();
  };

  const storeCertificates = async (certificate, update) => {
    try {
      const formData = new FormData();

      formData.append('attachment', certificate.attachment);
      formData.append('number', certificate.number);
      formData.append('emission', format(certificate.emission, 'yyyy-MM-dd'));

      const method = update ? 'put' : 'post';
      const response = await api.providers[method](
        `/api/v1/providers/${id}/certificates/${
          certificate.uuid_certificate || ''
        }`,
        formData
      );

      return response;
    } catch (exception) {
      throw new Error(
        `Erro ao ${update ? 'editar' : 'cadastrar'} o certificado!`
      );
    }
  };

  const handleOnSubmit = async (formData) => {
    try {
      buttonRef.current?.startLoad();

      const data = await certificatesSchema.validate(formData, defaultOptions);

      storeCertificates(data, data.uuid_certificate !== '');

      setTimeout(() => getCertificates({}), 200);
      modalFormRef.current?.close();
    } catch (error) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      } else {
        toast.error(error.message);
      }
    } finally {
      buttonRef.current?.finishLoad();
    }
  };

  const columns: Column = [
    {
      Header: 'Data',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
    },
    { Header: 'Nome do documento', accessor: 'name' },
    { Header: 'Número do documento', accessor: 'number' },
    {
      Header: 'Data de emissão',
      accessor: ({ emission }) => format(new Date(emission), 'dd/MM/yyyy'),
    },
    {
      Header: 'Documento',
      accessor: ({ attachment_display_name }) =>
        attachment_display_name || 'Documento',
    },
    {
      Header: 'Status',
      accessor: ({ deleted_at }) => (deleted_at ? 'Inativo' : 'Ativo'),
    },
  ];

  const handleClickOpenModal = (event) => {
    const { value } = event.target;

    if (event.target.id === 'justification') {
      setApiURL(`/api/v1/providers/${value}/certificates/${value}/changes`);
      justificationsModalRef.current?.open();
    } else {
      setApiURL(`/api/v1/providers/${id}/certificates/${value}`);
      inactiveModalRef.current?.open();
    }
  };

  const handleOnClickHover = useCallback(
    (value) => {
      const itemToEdit = data.data.find(
        (item) => item.uuid_certificate === value
      );

      if (itemToEdit) {
        modalFormRef.current?.open();
        formRef.current?.setData(itemToEdit);
      }
    },
    [formRef, data.data]
  );

  const getHoverComponent = ({ deleted_at, uuid_certificate }) => {
    return (
      <Hover value={uuid_certificate} onClick={handleOnClickHover}>
        <Actions>
          <IconButton
            id={deleted_at ? 'justification' : 'inactive'}
            value={uuid_certificate}
            onClick={handleClickOpenModal}
          >
            {deleted_at ? <Justification /> : <Inactive />}
          </IconButton>
        </Actions>
      </Hover>
    );
  };

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">Certificados cadastrados</Title>
      <ModalWrapper ref={justificationsModalRef}>
        <JustificationModal text="Certificado" getURL={apiURL} />
      </ModalWrapper>
      <ModalWrapper ref={inactiveModalRef}>
        <InactiveModal text="Certificado" postURL={apiURL} />
      </ModalWrapper>
      <ModalWrapper ref={modalFormRef}>
        <FormModal
          title="Certificado"
          formRef={formRef}
          buttonRef={buttonRef}
          onSubmit={handleOnSubmit}
        >
          <Input label="" name="uuid_certificate" hidden />
          <FormGroup columns="1fr 1fr 0.8fr 1fr">
            <Input label="Nome do documento" name="name" />
            <Input label="Número do documento" required name="number" />
            <DatePicker label="Data de emissão" name="emission" />
            <InputFile
              label="Anexar documento"
              name="attachment"
              accept=".pdf"
            />
          </FormGroup>
        </FormModal>
      </ModalWrapper>
      <Table
        allowControls
        columns={columns}
        data={data.data}
        loading={loading}
        totalData={data.total}
        newButtonLabel="Novo certificado"
        onChangePage={getCertificates}
        onClickNew={handleOnClickNew}
        hoverComponent={getHoverComponent}
      />
    </FormGroup>
  );
};

export default Certificates;
