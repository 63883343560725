/* eslint-disable react/display-name */

import { useCallback, useMemo, useRef, useState } from 'react';

import format from 'date-fns/format';
import { useHistory } from 'react-router';
import { Column } from 'react-table';
import FadeIn from 'src/components/FadeIn';
import IconButton from 'src/components/IconButton';
import { EditProviders, Inactive, Justification } from 'src/components/Icons';
import Table from 'src/components/Table';
import Hover from 'src/components/Table/Hover';
import Actions from 'src/components/Table/Hover/Actions';
import Title from 'src/components/Title';
import api from 'src/services/api';

import Indicators from './Indicators';
import { Container, Content } from './styles';

interface IUseHistoryState {
  providerURL?: string;
}

const List = (): JSX.Element => {
  const history = useHistory<IUseHistoryState | undefined>();

  const [loading] = useState(false);
  const [data] = useState({
    data: [],
    total: 0,
  });

  const handleOnClickNew = () => {
    history.push('/saidas/cadastro');
  };

  // Columns of table
  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Data',
        hide: true,
        accessor: ({ created_at }) =>
          created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
      },
      {
        Header: 'MTR',
        accessor: 'mtr',
      },
    ],
    []
  );

  return (
    <Container>
      <FadeIn>
        <Title variant="header">Saídas</Title>
        <Indicators />
        <Content>
          <Title variant="subtitle" icon={EditProviders}>
            Documentos de Saída
          </Title>
          <Table
            allowControls
            columns={columns}
            data={data.data}
            loading={loading}
            totalData={data.total}
            newButtonLabel="Novo Cadastro"
            onClickNew={handleOnClickNew}
          />
        </Content>
      </FadeIn>
    </Container>
  );
};

export default List;
