import { useRef, useState, useEffect } from 'react';

import { useField } from '@unform/core';
import ptBR from 'date-fns/locale/pt-BR';
import parseISO from 'date-fns/parseISO';
import magicMasks from 'magic-masks';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import InputErrorMessage from 'src/components/InputErrorMessage';
import Label from 'src/components/Label';
import 'react-datepicker/dist/react-datepicker.css';

import { Container } from './styles';

registerLocale('pt-BR', ptBR);

interface IDatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  label: string;
  required?: boolean;
}

const DatePicker = ({
  id,
  label,
  name,
  placeholder,
  required,
  ...rest
}: IDatePickerProps): JSX.Element => {
  const datepickerRef = useRef<ReactDatePicker>(null);

  const { clearError, fieldName, registerField, defaultValue, error } =
    useField(name);

  const [date, setDate] = useState(defaultValue || null);

  const handleOnFocus = () => clearError();

  useEffect(() => {
    if (datepickerRef.current)
      registerField({
        name: fieldName,
        ref: datepickerRef.current,
        getValue: (ref) => {
          if (ref?.props?.selected) {
            return ref.props.selected;
          }

          return '';
        },
        setValue: (ref, value: string | Date) => {
          if (value) {
            if (typeof value === 'string' && value.length === 10) {
              setDate(parseISO(value));
            } else {
              setDate(value);
            }
          } else {
            setDate(null);
          }
        },
        clearValue: (ref: any) => {
          ref.clear();
        },
      });
  }, [fieldName, registerField]);

  const handleOnChange = (event) => {
    if (event.type !== 'click' && datepickerRef.current) {
      const { value } = event.target;

      // event.target.value = magicMasks.date(value);
    }
  };

  return (
    <Container className={`root-select-container ${error}`}>
      <Label htmlFor={id}>
        {label}
        {required === true ? '*' : ''}
      </Label>
      <ReactDatePicker
        ref={datepickerRef}
        selected={date}
        onFocus={handleOnFocus}
        onChange={setDate}
        onChangeRaw={handleOnChange}
        dateFormat="dd/MM/yyyy"
        locale="pt-BR"
        placeholderText={placeholder}
        {...rest}
      />
      <InputErrorMessage
        fieldLabel={label}
        fieldName={fieldName}
        message={error}
      />
    </Container>
  );
};

export default DatePicker;
