import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  height: 110vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(119deg, #92e8c5 0%, #98af6c 100%) 0%
    0% no-repeat padding-box;
`;

export const ReCaptcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
`;

export const HrOrSSO = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const ImageLock = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const ImageUser = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const ImageSSO = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0%;

  > img {
    width: 50%;
    height: auto;
  }
`;

export const LeftBox = styled.div`
  width: 39%;
  justify-content: center;
  align-items: center;
`;

export const RightBox = styled.div`
  width: 65%;
  padding-left: 20px;
  padding-rigth: 15px;
  justify-content: center;
  align-items: center;
`;

export const FormLogin = styled.form`
  width: 60%;
  display: flex;
  padding: 30px;
  padding-right: 10px;
  padding-bottom: 0;
  border-radius: 10px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

export const TextEntre = styled.p`
  text-align: center;
  font: normal normal normal 13px/24px Roboto;
  color: #909090;
  opacity: 1;
  margin-bottom: 5px;
`;

export const MenuItemLink = styled.a`
  color: '#F7931B';
  text-decoration: none;
  justify-content: center;
  margin: 7px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  > svg {
    font-size: 18px;
    margin-right: 5px;
  }
`;

export const TextORSSO = styled.p`
  text-align: center;
  font: normal normal normal 13px/24px Roboto;
  color: #909090;
  opacity: 1;
  margin-bottom: 5px;
  background: #ffffff;
  position: absolute;
  padding: 15px;
  margin-top: 4px;
`;

export const HRORSSOs = styled.div`
  width: 100%;
  border-bottom: 1px solid #56ad39;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  margin-bottom: 10%;
`;

export const TextFooter = styled.p`
  text-align: center;
  font: normal normal normal 10px/24px Roboto;
  color: #606060;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 2%;
  margin-bottom: 5%;
  justify-content: center;
  cursor: pointer;
`;

export const LogoRight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: 40px;

  color: '#FFFFFF';

  &:after {
    content: '';
    display: block;
    width: 55px;
    border-bottom: 10px solid #e44c4e;
  }
`;
