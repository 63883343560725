import styled from 'styled-components';

interface IColorProps {
  color: 'primary' | 'secondary';
}

export const Container = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #808080;
`;

type IInputProps = IColorProps;
export const Input = styled.input<IInputProps>`
  display: none;

  &:not(:disabled):checked + div {
    border-color: ${({ color, theme }) =>
      theme.switch[color].active.borderColor};
    background-color: ${({ color, theme }) =>
      theme.switch[color].active.background};

    div {
      background-color: ${({ color, theme }) =>
        theme.switch[color].active.toggle.background};
    }
  }

  &:checked + div {
    background-color: ${({ theme }) => theme.switch.disabled.background};

    div {
      left: calc(100% - 1px);
      transform: translate(-100%, -50%);
    }
  }
`;

type IBoxProps = IColorProps;
export const Box = styled.div<IBoxProps>`
  position: relative;

  border-radius: 16px;
  border: 1px solid
    ${({ color, theme }) => theme.switch[color].normal.borderColor};
  background-color: ${({ color, theme }) =>
    theme.switch[color].normal.background};
`;

type IToggleProps = IColorProps;
export const Toggle = styled.div<IToggleProps>`
  position: absolute;

  top: 50%;
  left: 2px;

  transform: translateY(-50%);
  transition: all 200ms linear;

  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    theme.switch[color].normal.toggle.background};
`;

export const Text = styled.span`
  font-family: 'Roboto Condensed';
  font-size: 13px;
`;
