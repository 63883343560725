import { useLocation } from 'react-router';
import { Config, Logo } from 'src/components/Icons';

import Navigation from './Navigation';
import { Container, LogoBox, Menu } from './styles';

const Sidebar = (): JSX.Element => {
  const location = useLocation();

  return (
    <Container>
      <LogoBox>
        <Logo />
      </LogoBox>
      <Navigation />
      <Menu
        active={
          location.pathname.indexOf('/configuracoes') > -1 ? 1 : undefined
        }
        to="/configuracoes"
      >
        <Config />
      </Menu>
    </Container>
  );
};

export default Sidebar;
