import axios from 'axios';

const {
  REACT_APP_PROVIDERS_API_URL,
  REACT_APP_USERS_API_URL,
  REACT_APP_RESIDUES_API_URL,
  PV_ATUAL_DEV,
} = process.env;

const config = {
  headers: {
    Accept: 'application/json',
  },
};

const api = {
  user: axios.create({
    baseURL: REACT_APP_USERS_API_URL,
    ...config,
  }),
  pvdev: axios.create({
    baseURL: PV_ATUAL_DEV,
    ...config,
  }),
  providers: axios.create({
    baseURL: REACT_APP_PROVIDERS_API_URL,
    ...config,
  }),
  residues: axios.create({
    baseURL: REACT_APP_RESIDUES_API_URL,
    ...config,
  }),
};

export default api;
