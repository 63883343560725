import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 48px 1fr 48px;
  grid-gap: 48px;

  padding: 8px 0;

  width: 60px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

interface IMenuProps {
  active?: number;
}

export const Menu = styled(Link)<IMenuProps>`
  cursor: pointer;

  display: flex;
  align-self: center;
  justify-content: center;
  justify-self: center;

  padding: 8px;
  height: 40px;
  width: 40px;

  border-radius: 10px;
  border: 1px solid #e5e5e5;

  background-color: ${({ active }) => (active ? '#56ad39' : '#ffffff')};

  svg {
    display: flex;
    align-self: center;
    justify-content: center;
    height: 24px;

    max-height: 100%;

    fill: ${({ active, theme }) => (active ? '#ffffff' : theme.primary)};
  }
`;

export const LogoBox = styled.div`
  justify-self: center;

  svg {
    height: 48px;
    fill: ${({ theme }) => theme.primary};
  }
`;
